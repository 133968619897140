body{
	margin:0px;
}
.verifying-payment-wrapper{
	position:absolute;
	top:0px;
	left:0px;
	width:100%;
	height:100%;
	background-color:#f4f6f8;
	display:flex;
	flex-direction:column;
	justify-content:center;
	align-items:center;
}
.animate-verification-modal{
	position:fixed;
	top: 100%;
	left:0px;
	animation: moveDown 0.3s ease-in-out;
}
@keyframes moveDown {
	from {
	  transform: translateY(-100%);
	}
	to {
	  transform: translateY(0%);
	}
  }
.verifying-payment-card{
	width:350px;
	height:300px;
	border-radius: 3px;
	background-color: #ffffff;
	box-shadow: 0 1.2px 5.6px 0 rgb(0 0 0 / 12%);
	border-width: 1px;
	border-style: solid;
	border-color: #ffffff;
	display:flex;
	flex-direction:column;
	justify-content:center;
	align-items:center;
	position:relative;
}
.verifying-payment-card p{
	margin:0px;
}
.verifying-payment-card .title{
	font-size:18px;
}
.verifying-payment-card .description{
	font-size:15px;
	margin-top:10px;
	color:grey;
}
.verifying-payment-card .homepage-wrapper{
	width: 100%;
	text-align: center;
	background-color: #f4f6f8;
	padding: 10px 5px;
	align-self: flex-end;
	box-sizing: border-box;
	font-size: 14px;
	position: absolute;
	bottom: 0px;
	color: #1976d2;
}

.verifying-payment-card img{
	margin-top:30px;
	width:30px;
}

.contact-info{
	padding:10px;
	border-radius:30px;
	position:absolute;
	bottom:10px;
	font-size:14px;
}
.contact-info a{
	color:#1976d2;
}