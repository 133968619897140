.modal-wrapper{
	width:100%;
	height:100%;
	background-color:rgba(0,0,0,0.5);
	position:fixed;
	top:0px;
	left:0px;
	z-index:100;
	display:flex;
	flex-direction:row;
	justify-content:center;
	align-items:center;
}

.modal-wrapper .impact-modal{
	box-shadow:2px 5px 16px 2px rgb(82 82 82 / 50%);
	border-radius:4px;
	border:1px solid #b7b7b7;
	width:500px;
	background-color:white;
}
.modal-wrapper .impact-modal .impact-modal-header{
	display:flex;
	flex-direction:row;
	justify-content:flex-end;
}
.modal-wrapper .impact-modal .impact-modal-header .close-btn{
	padding:15px;
	background-color:transparent;
	border:none;
}
.modal-wrapper .impact-modal .impact-modal-header .close-btn img{
	width:20px;
}
.modal-wrapper .impact-modal .impact-modal-content{
	padding:20px 0px;
}
.modal-wrapper .impact-modal .impact-modal-content h5{
	text-align:center;
	margin-bottom:20px;
}

.modal-wrapper .impact-modal .impact-modal-content .payment-method-item{
	width: 100%;
	border: 1px solid #e6e7eb;
	background-color: #f5f7fb;
	color: #438ebd;
	/* margin-bottom: 10px; */
	padding: 20px;
	border-left:none;
	border-right:none;
	border-top:none;
	transition:background-color 400ms;
}
.modal-wrapper .impact-modal .impact-modal-content .payment-method-item:hover{
	background-color:#e9effb;
}

.form-wrapper{
	max-width:900px;
	padding:40px 30px;
	margin-left:auto;
	margin-right:auto;
}

.list-wrapper .mb-3{
	margin-bottom:0px !important;
}

b{
	margin-bottom:20px;
}

.payment-modal-wrapper{
	display:flex;
}

/* .appointment-modal-wrapper{
	display:none;
} */

.appointment-modal-wrapper .impact-modal{
	height:350px;
	position:relative;
}

.modal-wrapper .impact-modal .impact-modal-content.appointment-content{
	display:flex;
	flex-direction:column;
	align-items:center;
	padding:40px;
}
.modal-wrapper .impact-modal .impact-modal-content.appointment-content img{
	width:40px;
	margin-bottom:20px;
}
.modal-wrapper .impact-modal .impact-modal-content.appointment-content a{
	position:absolute;
	bottom:30px;
	padding-left:30px;
	padding-right:30px;
}
